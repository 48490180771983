import React from 'react';
import withSectionTracking from 'helpers/higher-order-components/section-tracking.hoc';
import Image from 'components/image/image';
import './data-prep.sass';

const Row = ({title, content, image, classes}) => (
  <div className={classes}>
    <p><b>{title}</b> - {content}</p>
    <Image image_url={image.url}
          alt={image.alt}
          className="w-100"/>
  </div>
)

const DataPrepMoreAbout = ({ data }) => (
  <section className="DataPrepMoreAbout k-bg--grey">
      <Image image_url="layout/bg-gs-stripes.svg"
            className="DataPrepMoreAbout__background DataPrepMoreAbout__background--stripes1"
            alt="section background of hexagon shape"/>

      <Image image_url="layout/bg-platform-video-stripes.svg"
            className="DataPrepMoreAbout__background DataPrepMoreAbout__background--stripes2"
            alt="section background of hexagon shape"/>

    <div className="container-fluid">
      <Image image_url="layout/bg-diamond.svg"
             alt="background diamond pattern big"
             className="DataPrepMoreAbout__background DataPrepMoreAbout__background--big"/>
      <Image image_url="layout/bg-diamond--blue.svg"
             alt="background diamond pattern small"
             className="DataPrepMoreAbout__background DataPrepMoreAbout__background--small"/>

      <h2 className="text-center mx-5 mb-5">{data.title}</h2>
      <div className="text-center">
          { data.points.map((point, index) => <Row key={index}
                                                   title={point.title}
                                                   content={point.content}
                                                   image={point.image}
                                                   classes={point.classes} />)
          }
      </div>
    </div>
  </section>
);

export default withSectionTracking(React.memo(DataPrepMoreAbout));
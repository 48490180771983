import React, { useRef, useState } from 'react';
import { navigate } from "gatsby"
import { emailValidationIssue } from 'components/forms/form-helpers';
import { Event } from 'helpers/analytics/event-tracking';
import Loader from 'components/loaders/loader'
import Input from 'components/forms/input';
import Button from 'components/button/button';
import Href from 'components/href/href';
import './data-prep.sass';

const EmailInUseLoginIn = ({ email, id }) => (
  <p className="text-dark">
    Email is already registered.
    <Href link={`${process.env.PLATFORM_HOST}/?username=${email}`}
          id={`${id}__email-registered-login-link`}>
      <b> Click here to login.</b>
    </Href>
  </p>
)

const DataEmailForm = ({ call_to_action, id, updatePlatformRegistration, className }) => {
  const [ errorMessage, setErrorMessage ] = useState(null);
  const [ loading, setLoading ] = useState(false);
  let formRef = useRef(null);

  const codeHandler = (code, email) => {
    switch (code) {
      case 'K4001':
        setErrorMessage(<EmailInUseLoginIn email={email} id={id}/>);
        Event('error', 'ml-data-prep data-email-form', 'email is already registered')
        break;
      case 'K4005':
        setErrorMessage('Email must be a valid email');
        Event('error', 'ml-data-prep data-email-form', 'primary email invalid')
        break;
      case 'K1002':
        updatePlatformRegistration('email', email);
        updatePlatformRegistration('step', 1);
        navigate('/ml-data-prep-registration/');
        break;
      default:
        setErrorMessage('Something unexpected went wrong, please try again later');
        Event('error', 'ml-data-prep data-email-form', 'something unexpected went wrong')
        break;
    }
  }

  const requestEmailVerification = (email) => {
    setLoading(true);

    fetch(process.env.PLATFORM_HOST + '/api/v1/account/validate', {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({ "email": email }),
    })
      .then(data => data.json())
      .then(data => {
        dataLayer.push({'event': 'gtm-ml-data-prep-email'}); //GTM Google Ads conversion tracking
        Event('ga-goals-ml-data-prep-email', `${id}__call-to-action`) // Needed for GA Goals because it can only track one to one relationships for events
        codeHandler(data.kortical_response_code, email);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        codeHandler('K9999');
      })
  }

  const onClick = () => {
    const form = formRef.current;
    const { value } = form['email'];

    const email_issue = emailValidationIssue(value, setErrorMessage);
    if (email_issue) {
      Event('error', 'ml-data-prep data-email-form', 'primary email invalid')
      return;
    }

    requestEmailVerification(value);
  }

  return (
    <div className={`DataEmailForm ${className}`}>
      <form ref={formRef}>
        <Input name="email"
              type="email"
              placeholder="Enter your email address"
              className="LandingPageForm__email-input fs-mask-without-consent"
              id={`${id}__email-input`}
              required >
          Email
        </Input>

        <Button className="btn"
                disabled={loading}
                id={`${id}__call-to-action`}
                callback={onClick}>
          { loading
              ? <Loader />
              : call_to_action
          }
        </Button>
      </form>
      { errorMessage
        ? <div className="my-2 w-100 text-red" role="alert">
            {errorMessage}
          </div>
        : null
      }
    </div>
  )
}

export default DataEmailForm
import React from 'react';
import withSectionTracking from 'helpers/higher-order-components/section-tracking.hoc';
import Image from 'components/image/image';
import DataEmailForm from './data-email-form';
import './data-prep.sass';
import ScrollButton from "../scroll-button/scroll-button";

const Feature = ({ feature }) => (
  <li className="LandingPageHero__feature">
    <Image image_url="icons/icon-check--pink.svg" alt="Checkpoint" className="pr-3 pt-2"/>
    <p>{feature}</p>
  </li>
)

const LandingPageHero = ({ data, benefits, updatePlatformRegistration }) => {
  const featuresList = benefits.map((feature, i) => {
    return <Feature feature={feature} key={i}/>
  });

  return (
    <div className="LandingPageHero">
      <div className="LandingPageHero__container container-fluid d-flex justify-content-between">
        <Image image_url="layout/bg-hexagon-filled.svg"
              className="LandingPageHero__background"
              alt="Hero section background of hexagon filled shape" />
        <Image image_url="layout/bg-diamond--blue.svg" alt="background diamond pattern empty small" className="LandingPageHero__diamond LandingPageHero__diamond--small"/>
        <Image image_url="layout/bg-diamond.svg" alt="background diamond pattern empty big" className="LandingPageHero__diamond LandingPageHero__diamond--big"/>

        <div className="LandingPageHero__call-to-action">
          <h1 className="h2--new">{data.title}</h1>
          <h4>{data.subtitle}</h4>
          <ul className="mb-3 p-0">{featuresList}</ul>
          <div className={`LandingPageHero__call-to-action__sign-up`}>
            <ScrollButton
              to="#k-anchor__get-in-touch"
              className="btn">
              Get data prep
            </ScrollButton>
          </div>
        </div>

        <div className="LandingPageHero__preview__container">
          <Image image_url="content/img-data-prep-preview/img-data-prep-preview.png"
                className="LandingPageHero__preview"
                alt="Kortical platform's Data Prep feature preview" />
        </div>
      </div>
    </div>
  )
}

export default withSectionTracking(React.memo(LandingPageHero))

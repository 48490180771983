import { sendClickedEvent } from 'helpers/analytics/event-tracking';
import React from 'react';

// IDs used for button should follow BEM naming convention used by event-tracking component
const Button = ({ callback, disabled, children, className, id }) => {

  function handleClick (event, callback) {
    event.preventDefault();
    sendClickedEvent(event);

    if (callback) {
      callback(event);
    }
  }

  return (
    <button onClick={(e) => handleClick(e, callback)}
            disabled={disabled ? disabled : false}
            className={className}
            id={id}>
      {children}
    </button>
  );
};

export default Button
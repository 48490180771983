import React from 'react'
import { graphql } from 'gatsby'
import { connect } from 'react-redux'
import { UPDATE_PLATFORM_REGISTRATION } from '../store/actions'
import Layout from 'components/layout'
import SEO from 'components/seo/seo'
import LandingPageHero from 'components/data-prep/data-prep-hero'
import DataPrepAboutScreenshots from 'components/data-prep/data-prep-about-screenshots'
import ClientList from 'components/client-list/client-list'
import DataPrepMoreAbout from 'components/data-prep/data-prep-more-about'
import GetInTouch from 'components/get-in-touch/get-in-touch'



const DataPrepPage = ({ data, data_prep_registration_reducer, updatePlatformRegistration }) => {
  const d = data.landingPagesYaml;

  return (
    <Layout hide_contact={true}
            footerClass={data_prep_registration_reducer.step !== 0 ? 'Footer-reverse Footer-short' : 'Footer-reverse'}
            headerClass={'Header--ml-data-prep'}>
      <SEO
        title="ML EDA and Data Prep | Assisted Feature Engineering - Free"
        keywords={['AI', 'Machine Learning', 'AutoML', 'AI Consulting', 'ML', 'Artificial Intelligence']} />
      <LandingPageHero data={d.hero}
                          benefits={d.benefits}
                          updatePlatformRegistration={updatePlatformRegistration}
                          sectionId="Data-prep-page__hero-section"/>
      <DataPrepAboutScreenshots data={d.screenshots} sectionId="Data-prep-page__about-screenshots"/>
      <h2 className="text-center mb-n5">Our Clients</h2>
      <ClientList data={data.mainYaml.clientList} sectionId="Data-prep-page__clients" has_text={false} has_title={true} />
      <DataPrepMoreAbout data={d.more_about} sectionId="Data-prep-page__about-block-text"/>
      <GetInTouch />
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    data_prep_registration_reducer: state.registrationReducer.data_prep[state.registrationReducer.data_prep.length - 1]
  }
}

const mapDispatchToProps = dispatch => ({
  updatePlatformRegistration: (field, data) => {
    dispatch(UPDATE_PLATFORM_REGISTRATION(field, data))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(DataPrepPage)

export const pageQuery = graphql`
  query {
    mainYaml {
      clientList {
        name
        logo
        figure
        fact
        time
        link
      }
    }
    landingPagesYaml {
      hero {
        title
        subtitle
        call_to_action
        call_to_acton_subtitle
      }
      benefits
      screenshots {
        title
        points {
          title
          icon
          screenshot
          content
        }
      }
      more_about {
        title
        points {
          title
          content
          classes
          image {
            url
            alt
          }
        }
      }
      footer_call_to_action {
        title
        input_text
        call_to_action
      }
    }
  }
`
import { sendClickedEvent } from 'helpers/analytics/event-tracking';
import React from 'react';

// IDs used for hrefs should follow BEM naming convention used by event-tracking component
const Href = ({ link, disabled, children, className, id }) => {
  function handleClick (event) {
    event.preventDefault();
    const _event = {target: {id: event.currentTarget.id}}
    sendClickedEvent(_event);

    if (typeof window !== 'undefined' && window.location){
      window.location.href = link;
    }
  }

  return (
    <a href="#"
        onClick={(e) => handleClick(e)}
        disabled={disabled ? disabled : false}
        className={className}
        id={id}>
      {children}
    </a>
  );
};

export default Href
import React, { useState } from 'react'
import { Link } from 'gatsby'
import { Carousel } from 'react-responsive-carousel'
import withSectionTracking from 'helpers/higher-order-components/section-tracking.hoc'
import { useInterval } from 'helpers/hooks/useInterval'
import Image from 'components/image/image'
import './client-list.sass'

const ClientsMobile = (data, has_text) => {
  return data.map((client, i) => (
      <Link to={client.link ? client.link : "#"} className="text-dark" key={i}>
      <div className="Client__box Client__mobile d-flex flex-column justify-content-center text-center k-px-container" key={i}>
        <Image key={i}
              image_url={`clients/${client.logo}`}
              className="k-margin-0-auto mb-3"
              alt={`${client.name} client logo`} />
        {has_text
        ? <React.Fragment>
              <p className="Client__figure mt-3">{client.figure}</p>
              <p className="Client__fact">{client.fact}</p>
              <p className="Client__time">{client.time}</p>
              <p>
                <span className={client.link !== null ? `Client__link` : `Client__link--hide` } >
                  Case Study <span>&rarr;</span>
                </span>
              </p>
            </React.Fragment>
          : null
          }
      </div>
    </Link>
    )
  )
}

const ClientsDesktop = ({ data, has_text }) => {
  const [counter, setCounter] = useState(0)
  const [isHovered, setIsHovered] = useState(false)
  const changeTime = 1500

  useInterval(async () => {
    if (!isHovered) {
      if (counter >= data.length - 1) {
        await setCounter(0)
      } else {
        setCounter(counter + 1)
      }
    }
  }, changeTime)

  const setHoveredClient = (index) => {
    setIsHovered(true)
    setCounter(index)
  }

  return data.map((client, i) => (
      <div  className="Client__desktop" key={i}
                                        onMouseEnter={() => setHoveredClient(i)}
                                        onMouseLeave={() => setIsHovered(false)}>
        <Link to={client.link ? client.link : "#"} className="text-dark">
          <div className={`Client__box text-center ${ counter === i ? 'Client__box--selected' : null}`}>
              <div className="Client__desktop__logo">
                <img
                  key={i}
                  src={require(`images/clients/${client.logo}`).default}
                  alt={`${client.name} client logo`}/>
              </div>
              {has_text
               ? <React.Fragment>
                    <p className="Client__figure mt-3">{client.figure}</p>
                    <p className="Client__fact">{client.fact}</p>
                    <p className="Client__time">{client.time}</p>

                    <p>
                      <span className={client.link !== null ? `Client__link` : `Client__link--hide` }>
                        Case Study <span>&rarr;</span>
                      </span>
                    </p>
                  </React.Fragment>
                : null
              }
          </div>
        </Link>
      </div>
  ))
}

const ClientList = ({ data, has_text, has_title }) => (
  <div className="ClientList">
    <div className="Client container-fluid">
      {has_title
      ? <div className="mb-5 d-flex justify-content-center">
        <h3 className="m-0">We've helped tons of people</h3>
      </div>
      : null}
      <div className="Client__desktop__container">
        <ClientsDesktop data={data} has_text={has_text}/>
      </div>
      <div className="Client__mobile__container mt-5">
        <Carousel
          showArrows={false}
          showStatus={false}
          showThumbs={false}
          emulateTouch={true}
          swipeScrollTolerance={50}
          transitionTime={150}>
            {ClientsMobile(data, has_text)}
        </Carousel>
      </div>
    </div>
  </div>
)

export default withSectionTracking(React.memo(ClientList))

import React, { useEffect, useRef } from 'react';
import withSectionTracking from 'helpers/higher-order-components/section-tracking.hoc';
import { sendClickedEvent } from 'helpers/analytics/event-tracking';
import Image from 'components/image/image';
import './data-prep.sass';

const Row = ({ title, icon, screenshot, content, reverse}) => {
  const rowEl = useRef(null);

  useEffect(() => {
    const links = rowEl.current.querySelectorAll('a');
    links.forEach(link => link.addEventListener('click', (e) => sendClickedEvent(e)))

    return () => {
      links.forEach(link => link.removeEventListener('click', (e) => sendClickedEvent(e)))
    }
  }, [])

  return (
    <div className={`DataPrepAboutScreenshots__Row d-flex ${reverse ? 'DataPrepAboutScreenshots__Row__flex-row-reverse' : ''} mb-5 justify-between`}
         ref={rowEl}>
      <div className="DataPrepAboutScreenshots__Row__content__container col-lg-5 col-12">
        <Image image_url={icon}
              className="DataPrepAboutScreenshots__Row__icon mb-4"
                alt={`section icon`} />
        <h4 className='h4-big'>{title}</h4>
        <p>
          <span dangerouslySetInnerHTML={{ __html: content}} />
        </p>
      </div>

      <div className='col-lg-7 col-12'>
        <Image image_url={screenshot}
                alt={`section icon`}
                className="w-100"/>
      </div>
    </div>
  )
}
const DataPrepAboutScreenshots = ({ data }) => {
  const rowPoints = data.points.map((point, i) => {
    return <Row title={point.title}
                icon={point.icon}
                screenshot={point.screenshot}
                content={point.content}
                key={i}
                reverse={i % 2 !== 0}/>
  })

  return (
    <section className="DataPrepAboutScreenshots">
      <div className="container-fluid">
        <h2 className="text-center mx-5 mb-5">{data.title}</h2>
        {rowPoints}
      </div>
    </section>
  );
};

export default withSectionTracking(React.memo(DataPrepAboutScreenshots));